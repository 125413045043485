body {
  font: unset;
  font-size: 17px;
  background-color: transparent;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-small-logo {
  height: 20vmin;
  align-items: center;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.centered-container {
  display: block;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  /*vertical-align: middle;*/
  text-align: center;
}

.sample-centered-container {
  display: block;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  /*vertical-align: middle;*/
  text-align: center;
  color: white;
  font-size: calc(10px + 2vmin);
}

.centered-container-stage {
  display: block;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  /*vertical-align: middle;*/
  text-align: center;
  background-color: #1a1a1a;
}

.wego-listelement {
  color: #a0a0a0;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

.wego-container {
  color: white !important;
}

.wego-tilecontainer {
  margin-top: 10px;
  overflow: hidden;
  /*background: #F0F0F0;*/
  color: white !important;
  padding: 0px;
  border-radius: 2px;
}

.wego-header {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 5px;
}

.wego-button {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 8px;
  padding-right: 6px;
  padding-left: 6px;
  padding-bottom: 2px;
  font-size: 0.9em;
  font-family: "Noto Sans TC";
  font-weight: 700;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
}

.wego-column-button {
  min-width: 55px;
  margin-top: 4px;
  line-height: 1.4em;
  color: white;
  background-color: #6264a7;
  border-color: #6264a7; /*#7c76f6;*/
  font-size: 15px;
  font-weight: 400;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.wego-lobby-primary-button {
  min-width: 55px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-right: 0px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 1.4em;
  color: white;
  background-color: #6264a7; /*#7457bc;*/
  border-color: #6264a7;
  font-size: 15px;
  float: right;
  font-weight: 400;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.wego-element-parent {
  display: table;
  width: 100%;
  vertical-align: bottom;
  text-align: left;
  margin-top: 0px;
  font-weight: 600;
  font-size: 14px;
  color: #f0f0f0;
}

.wego-left-element {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  color: #f0f0f0;
}

.wego-right-primary-button {
  min-width: 75px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-right: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: white;
  background-color: #6264a7;
  border-color: #6264a7;
  font-size: 16px;
  float: right;
  font-weight: 400;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.wego-right-button {
  min-width: 75px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-right: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: black;
  background-color: white;
  font-size: 16px;
  float: right;
  font-weight: 400;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.wego-button-text {
  margin-top: 1em;
  font-size: 0.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe {
  width: 100%;
  height: 100vh;
}
