.wego-themed-container-dark  {
   color: white !important;
}

.wego-themed-container-dark .form-label {
    color: lightgray !important;
 }

.wego-themed-container-light  {
    color: black !important;
}